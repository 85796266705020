import { computed, defineComponent } from 'vue';
import styles from './index.css?module';
import { BottomMenu, BottomMenuItem } from '@/components/ui';
import { useRoute } from 'vue-router';
import { useRouteBuilder } from '@/composables';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup(props, { slots }) {
    const { t } = useI18n();
    const route = useRoute();
    const routeBuilder = useRouteBuilder();

    const menuItems = computed((): BottomMenuItem[] => {
      return [
        {
          title: t('menu.ads'),
          icon: 'fire-solid',
          activeIconStyle: 'text-textHighlight-danger',
          url: routeBuilder.enrichQuery(routeBuilder.adsList()),
          isActive: route.name === 'ads',
        },
        {
          title: t('menu.chats'),
          icon: 'chat-bubble-ellipsis',
          activeIconStyle: 'text-primary',
          url: routeBuilder.chatsList(),
          isActive: route.name === 'chats',
        },
        {
          title: t('menu.profile'),
          icon: 'user-circle-solid',
          activeIconStyle: 'text-textHighlight',
          url: routeBuilder.userDetail(),
          isActive: route.name === 'user',
        },
      ] as BottomMenuItem[];
    });

    const showBottomMenu = computed(() => {
      return route.meta?.showBottomMenu ?? false;
    });

    const renderBottomMenu = () => {
      return showBottomMenu.value && <BottomMenu items={menuItems.value} />;
    };

    return () => (
      <>
        <div
          class={[styles.Layout, showBottomMenu.value && styles.LayoutWithMenu]}
        >
          {slots?.default?.()}
        </div>
        {renderBottomMenu()}
      </>
    );
  },
});
