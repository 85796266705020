import { defineStore } from 'pinia';
import { ref, readonly } from 'vue';
import { IMeeting } from '@/api/meeting';
import { api, IGetListRequest } from '@/api/meeting';

export const useMeetingStore = defineStore('meeting', () => {
  const meetingList = ref<IMeeting[]>([]);

  const removeMeetingByUserId = async (userId: string) => {
    meetingList.value = meetingList.value.filter(
      (v) => v.profile.userId != userId
    );
  };

  const loadMeetingList = async (payload: IGetListRequest) => {
    const resp = await api.getList(payload);
    if (resp.ok) {
      meetingList.value = resp.payload.data;
    }

    return resp;
  };

  const loadMoreMeetingList = async (payload: IGetListRequest) => {
    const resp = await api.getList(payload);
    if (resp.ok) {
      meetingList.value = meetingList.value.concat(resp.payload.data);
    }

    return resp;
  };

  return {
    meetingList: readonly(meetingList),
    loadMeetingList,
    loadMoreMeetingList,
    removeMeetingByUserId,
  };
});
