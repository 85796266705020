import { defineComponent, PropType } from 'vue';
import styles from './index.css?module';
import { IMeeting } from '@/api/meeting';
import { MeetingCard } from '@/components/meeting';

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Readonly<IMeeting[]>>,
      required: true,
    },
  },

  setup(props) {
    return () => (
      <div class={styles.ListWrapper}>
        {props.items.map((item) => {
          return (
            <MeetingCard key={`meet-${item.profile.userId}`} item={item} />
          );
        })}
      </div>
    );
  },
});
