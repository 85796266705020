export function useEnv() {
  const isDeveloperMode = (): boolean => {
    return process.env?.VUE_APP_ENVIRONMENT === 'dev';
  };

  const tgDebugData = (): string => {
    return process.env?.VUE_APP_TG_DEBUG_DATA;
  };

  const baseUrl = (): string => {
    return process.env.BASE_URL;
  };

  const s3Url = (): string => {
    return process.env.VUE_APP_S3_URL;
  };

  const backendUrl = (): string => {
    return process.env.VUE_APP_BACKEND_URL;
  };

  const analyticToken = (): string => {
    return process.env.VUE_APP_ANALYTIC_TOKEN;
  };

  const analyticAppName = (): string => {
    return process.env.VUE_APP_ANALYTIC_APP_NAME;
  };

  return {
    isDeveloperMode,
    tgDebugData,
    baseUrl,
    s3Url,
    backendUrl,
    analyticToken,
    analyticAppName,
  };
}
