import { api as client, Response } from '@/api';
import { IGetListRequest, IGetListResponse } from '@/api/meeting/types';

export default {
  getList: async (
    payload: IGetListRequest
  ): Promise<Response<IGetListResponse>> => {
    return await client.get<IGetListResponse, IGetListRequest>(
      '/api/meeting/list',
      payload
    );
  },
};
