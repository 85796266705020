import { defineComponent, PropType, computed } from 'vue';
import styles from './index.css?module';
import { IMeeting } from '@/api/meeting';
import {
  Avatar,
  AvatarSize,
  AvatarVariant,
  BtnSize,
  BtnVariant,
  Button,
  Icon,
  Text,
  TextSize,
  TextColor,
} from '@/components/ui';
import { useTelegramWebApp } from '@/plugins';
import { TgPopupButton } from '@/plugins/telegram/types';
import { useI18n } from 'vue-i18n';
import { ToastType, useCupidStore, useMeetingStore, useToast } from '@/store';
import { useSemaphore } from '@/composables';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Readonly<IMeeting>>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const toastStore = useToast();
    const semaphore = useSemaphore();
    const cupidStore = useCupidStore();
    const tgWebApp = useTelegramWebApp();
    const meetingStore = useMeetingStore();

    const profileName = computed(() => {
      return props.item.profile.name;
    });

    const profilePhoto = computed(() => {
      const photos = props.item.profile.photos;
      return photos.length > 0 ? photos[0].path : '';
    });

    const onlineStatus = computed(() => {
      const online = props.item?.profile?.online;
      return online ? online.status : '';
    });

    const avatarVariant = computed((): AvatarVariant => {
      const variants = Object.values(AvatarVariant).filter(
        (v) => !isNaN(Number(v))
      );
      const choice = +props.item.profile.userId % variants.length;
      return variants[choice] as AvatarVariant;
    });

    const onDeleteChat = (e: Event) => {
      e.stopPropagation();

      tgWebApp.showPopup(
        t('views.ads.attention'),
        t('views.ads.toastDeleteChat'),
        [
          {
            id: 'reject',
            text: t('views.ads.cancelBtnTxt'),
            type: 'cancel',
          },
          {
            id: 'approve',
            text: t('views.ads.deleteBtnTxt'),
            type: 'destructive',
          },
        ] as TgPopupButton[],
        (id: string) => {
          if (id !== 'approve') {
            return;
          }
          deleteChat();
        }
      );
    };

    const deleteChat = async () => {
      if (!semaphore.tryAcquire()) {
        return;
      }

      const resp = await cupidStore.dislike(props.item.profile.userId);
      if (resp.ok) {
        meetingStore.removeMeetingByUserId(props.item.profile.userId);
      } else {
        toastStore.add(ToastType.ERROR, resp.message);
      }

      semaphore.release();
    };

    const onOpenChat = () => {
      if (props.item?.chat?.tgChatLink?.length > 0) {
        tgWebApp.openTelegramLink(props.item?.chat?.tgChatLink);
      }
    };

    return () => (
      <div class={styles.Card} onClick={onOpenChat}>
        <div class={'flex flex-row'}>
          <div class={'mr-4'}>
            <Avatar
              size={AvatarSize.X16}
              name={profileName.value}
              photo={profilePhoto.value}
              variant={avatarVariant.value}
            />
          </div>
          <div class={'flex flex-col mt-2'}>
            <Text size={TextSize.H4}>{profileName.value}</Text>
            <Text size={TextSize.BASE} color={TextColor.SECONDARY}>
              {onlineStatus.value}
            </Text>
          </div>
        </div>
        <div class={'flex flex-col justify-center'}>
          <Button
            variant={BtnVariant.TRANSPARENCY}
            size={BtnSize.INHERIT}
            onClick={onDeleteChat}
          >
            <Icon class={['text-xl', 'text-textSecondary']} name={'trash'} />
          </Button>
        </div>
      </div>
    );
  },
});
