import { computed, defineComponent, PropType } from 'vue';
import styles from './index.css?module';
import { Text, TextSize, TextWeight, TextColor } from '@/components/ui';
import { useS3 } from '@/composables';

export enum VARIANT {
  PRIMARY,
  PRIMARY_LIGHT,
  RED,
  YELLOW,
  GREEN,
  VIOLET,
  PINK,
}

export enum SIZE {
  X28,
  X16,
}

export default defineComponent({
  props: {
    variant: {
      type: Number as PropType<VARIANT>,
      default: VARIANT.PRIMARY,
    },
    size: {
      type: Number as PropType<SIZE>,
      default: SIZE.X28,
    },
    name: {
      type: String,
    },
    photo: {
      type: String,
    },
    onClick: {
      type: Function,
    },
  },

  setup(props, { slots, emit }) {
    const media = useS3();
    const initials = computed((): string => {
      if (props.name) {
        return props.name[0].toUpperCase();
      }
      return '';
    });

    const hasPhoto = computed((): boolean => {
      return (props?.photo?.length ?? 0) > 0;
    });

    const variantStyle = (): string => {
      switch (props.variant) {
        case VARIANT.PRIMARY:
          return styles.avatarPrimary;
        case VARIANT.PRIMARY_LIGHT:
          return styles.avatarPrimaryLight;
        case VARIANT.RED:
          return styles.avatarRed;
        case VARIANT.YELLOW:
          return styles.avatarYellow;
        case VARIANT.GREEN:
          return styles.avatarGreen;
        case VARIANT.VIOLET:
          return styles.avatarViolet;
        case VARIANT.PINK:
          return styles.avatarPink;
      }
    };

    const sizeStyle = (): string => {
      switch (props.size) {
        case SIZE.X28:
          return styles.avatarX28;
        case SIZE.X16:
          return styles.avatarX16;
      }
    };

    const textSize = (): TextSize => {
      switch (props.size) {
        case SIZE.X28:
          return TextSize.H1;
        case SIZE.X16:
          return TextSize.H3;
      }
    };

    const avatarStyles = computed((): string => {
      if ((props.photo?.length ?? 0) <= 0) {
        return '';
      }
      return `background-image: url("${media.original(
        props.photo as string
      )}")`;
    });

    return () => (
      <div
        class={[styles.avatar, sizeStyle(), variantStyle()]}
        style={avatarStyles.value}
        onClick={() => emit('click')}
      >
        {!hasPhoto.value && (
          <Text
            size={textSize()}
            color={TextColor.WHITE}
            weight={TextWeight.BOLD}
          >
            {!slots?.default && initials.value}
          </Text>
        )}
        {slots?.default && (
          <div class={styles.inputRightIcon}>{slots?.default?.()}</div>
        )}
      </div>
    );
  },
});
