import { computed, defineComponent, PropType } from 'vue';
import styles from './index.css?module';

export enum SIZE {
  X16,
  X20,
  X24,
  X28,
  X32,
  X36,
}

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number as PropType<SIZE>,
      default: SIZE.X16,
    },
  },

  setup(props) {
    const iconClass = computed((): string => {
      return `icon-${props.name}`;
    });

    const sizeStyle = () => {
      switch (props.size) {
        case SIZE.X16:
          return styles.iconX16;
        case SIZE.X20:
          return styles.iconX20;
        case SIZE.X24:
          return styles.iconX24;
        case SIZE.X28:
          return styles.iconX28;
        case SIZE.X32:
          return styles.iconX32;
        case SIZE.X36:
          return styles.iconX36;
      }
    };

    return () => <i class={[styles.icon, iconClass.value, sizeStyle()]}></i>;
  },
});
