import { defineComponent, PropType } from 'vue';
import styles from './index.css?module';
import { RouteLocationNamedRaw, useRouter } from 'vue-router';
import { Icon, IconSize, Text, TextColor, TextWeight } from '@/components/ui';

export interface BottomMenuItem {
  title: string;
  icon: string;
  activeIconStyle: string;
  url: string | RouteLocationNamedRaw;
  isActive: boolean;
}

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Readonly<BottomMenuItem[]>>,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();

    const onClick = async (item: BottomMenuItem) => {
      await router.push(item.url);
    };

    const itemIconStyle = (item: BottomMenuItem) => {
      if (!item.isActive) {
        return 'text-textSecondary';
      }
      return item.activeIconStyle;
    };

    const textIconStyle = (item: BottomMenuItem) => {
      if (!item.isActive) {
        return TextColor.SECONDARY_DARK;
      }
      return TextColor.PRIMARY;
    };

    return () => (
      <div class={styles.Menu}>
        {props.items.map((item, index) => {
          return (
            <div
              key={`menu-item${index}`}
              class={styles.MenuItem}
              onClick={() => onClick(item)}
            >
              <Icon
                class={itemIconStyle(item)}
                name={item.icon}
                size={IconSize.X28}
              />
              <Text
                class={'text-xs'}
                color={textIconStyle(item)}
                weight={TextWeight.LIGHT}
              >
                {item.title}
              </Text>
            </div>
          );
        })}
      </div>
    );
  },
});
