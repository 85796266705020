import { defineComponent, onMounted, onUnmounted, ref, computed } from 'vue';
import {
  Center,
  Loader,
  LoaderSize,
  Text,
  TextColor,
  TextSize,
} from '@/components/ui';
import { useAppStore, useMeetingStore } from '@/store';
import { useEndOfScroll, useSemaphore } from '@/composables';
import { MeetingList } from '@/components/meeting';
import { Vue3Lottie } from 'vue3-lottie';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup: () => {
    const { t } = useI18n();
    const appStore = useAppStore();
    const meetingStore = useMeetingStore();
    const semaphore = useSemaphore();
    const { isEndOfScroll, checkEndOfScroll } = useEndOfScroll(200);

    const initialized = ref(false);
    const currentPage = ref(1);
    const hasMorePage = ref(false);
    const listEl = ref(null as HTMLElement | null);

    const showEmptyState = computed(() => {
      return initialized.value && meetingStore.meetingList.length <= 0;
    });

    const showMeetingList = computed(() => {
      return initialized.value && meetingStore.meetingList.length > 0;
    });

    const updateButtons = () => {
      appStore.hideBackButton();
      appStore.hideMainButton();
    };

    const loadMoreAdList = async () => {
      if (!hasMorePage.value || !semaphore.tryAcquire()) {
        return;
      }

      const resp = await meetingStore.loadMoreMeetingList({
        page: currentPage.value + 1,
      });

      if (resp.ok) {
        currentPage.value += 1;
        hasMorePage.value = resp.payload.hasMore;
      }

      semaphore.release();
    };

    const reloadAdList = async () => {
      const resp = await meetingStore.loadMeetingList({
        page: 1,
      });

      if (resp.ok) {
        currentPage.value = 1;
        hasMorePage.value = resp.payload.hasMore;
      }

      initialized.value = true;
    };

    onMounted(async () => {
      updateButtons();

      await reloadAdList();
      initialized.value = true;
      document.body.classList.add('fixScreenScroll');
    });

    onUnmounted(() => {
      document.body.classList.remove('fixScreenScroll');
    });

    const onEndOfScroll = async () => {
      if (listEl.value == null) {
        return;
      }

      checkEndOfScroll(listEl.value);
      if (isEndOfScroll.value) {
        await loadMoreAdList();
      }
    };

    return () => (
      <>
        {!initialized.value ? (
          <Center>
            <Loader size={LoaderSize.XL} />
          </Center>
        ) : (
          <>
            {showMeetingList.value && (
              <div
                ref={listEl}
                class={'pb-4 h-full overflow-y-scroll overflow-x-hidden'}
                onScroll={onEndOfScroll}
              >
                <MeetingList items={meetingStore.meetingList} />
              </div>
            )}

            {showEmptyState.value && (
              <Center>
                <div class={'flex flex-col items-center text-center'}>
                  <Vue3Lottie
                    class={'w-40 h-40'}
                    height={160}
                    width={160}
                    animationData={require('@/assets/images/duck_airplane.json')}
                  />
                  <Text size={TextSize.H4} color={TextColor.SECONDARY}>
                    {t('views.meeting.notFound')}
                  </Text>
                  <Text size={TextSize.H4} color={TextColor.SECONDARY}>
                    {t('views.meeting.notFoundDesc')}
                  </Text>
                </div>
              </Center>
            )}
          </>
        )}
      </>
    );
  },
});
